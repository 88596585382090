<template>
  <b-modal
    v-model="showModal"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="modal-user-deleted">
      <img src="@/assets/images/warning.jpg" />
      <div align="center">
        <h6 class="fw600">Tài khoản không tồn tại</h6>
        <p class="f14 text-second-color">
          Tài khoản của bạn không tồn tại trong hệ thống. Đăng nhập tài khoản
          khác hoặc liên hệ Hotline để được hỗ trợ.
        </p>
        <div class="row">
          <div class="col-6">
            <a href="tel:1900636956" style="text-decoration: none">
              <b-button variant="light" class="w-100"> Gọi Hotline </b-button>
            </a>
          </div>
          <div class="col-6">
            <b-button variant="primary" class="w-100" @click="$emit('close')">
              Quay lại
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
  },
};
</script>

<style scoped>
.modal-user-deleted {
  padding: 14px;
}

.modal-user-deleted img {
  display: block;
  margin: 0 auto;
}

.btn {
  padding-top: 12px;
  padding-bottom: 12px;
}

.btn-light {
  color: #3e69f6;
  background-color: #ecf0fe;
}
</style>
